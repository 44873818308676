import { render, staticRenderFns } from "./socialDetails.vue?vue&type=template&id=6b8f90d5&scoped=true&"
import script from "./socialDetails.vue?vue&type=script&lang=js&"
export * from "./socialDetails.vue?vue&type=script&lang=js&"
import style0 from "./socialDetails.vue?vue&type=style&index=0&lang=css&"
import style1 from "./socialDetails.vue?vue&type=style&index=1&id=6b8f90d5&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8f90d5",
  null
  
)

export default component.exports